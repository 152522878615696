<template>
  <div>
    <div v-if="error" class="bg-red-500 text-white p-2">{{ error }}</div>
    <div v-else-if="message" class="bg-green-500 text-white p-2">{{ message }}</div>
    <UILoading v-else-if="api.loading.value || loading" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import useApi from '@/hooks/useApi.js'
import UILoading from '@/components/UI/Loading'

const api = useApi()
const route = useRoute()
const apiData = computed(() => ({
  key: decodeURIComponent(route.query.key),
}))

const loading = ref(true)
const error = ref(null)
const message = ref(null)

onMounted(() => {
  if (!apiData.value.key) {
    alert('Ongeldige link, contacteer ons via mail.')
    return
  }

  api.post('ONDERAANNEMING_ACCEPT', apiData).then(({ data }) => {
    error.value = data.error || null
    message.value = data.message || null
  }).finally(() => {
    loading.value = false
  })
})

</script>
